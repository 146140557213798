import React, { useState } from 'react';
import Reveal from './Reveal.tsx';
import image5 from '../assets/img/Menu/Speciani1.png';
import image6 from '../assets/img/Menu/Specialni2.png';

function Specials() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const images = [image5, image6];

  function toggleFullscreen(index = null) {
    setCurrentImageIndex(index);
    setIsFullscreen((prevState) => !prevState);
  }

  const FullscreenImage = () => (
    <div
      className="flex justify-center items-center bg-black bg-opacity-80 fixed inset-0 z-50"
      onClick={() => toggleFullscreen()}
    >
      <img
        className="w-[360px] h-[480px] md:h-[680px] md:w-[510px]"
        src={images[currentImageIndex]}
        alt="Fullscreen Special Image"
      />
    </div>
  );

  return (
    <div>
      {isFullscreen && <FullscreenImage />}
      <div className="bg-specials xl:bg-fixed bg-scroll bg-cover bg-center xl:bg-cover lg:bg-no-repeat flex">
        <div className="w-full bg-black bg-opacity-80">
          <div className="m-10 text-center pt-10 xl:pt-16">
            <Reveal>
              <h1 className="text-orange font-header text-4xl md:text-5xl xl:text-7xl">SVATOMARTINSKÉ HODY</h1>
            </Reveal>
            <p className="text-white font-body pt-6 text-2xl md:text-3xl xl:text-4xl">
              <Reveal>
              Pátek 15 - 22 hodin
              </Reveal>
              <Reveal delay={0.25}>
              Sobota 11 - 22 hodin
              </Reveal>
              <Reveal>
              Neděle 11 - 18 hodin
                <br />
              Pondělí 11 - 21 hodin
              </Reveal>
            </p>
          </div>
          <div className="pb-24 flex flex-col items-center gap-6 w-screen">
            <div className="flex flex-col md:flex-row gap-6">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Special Menu Image ${index + 1}`}
                  className="rounded-3xl w-64 md:w-72 xl:w-96"
                  onClick={() => toggleFullscreen(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specials;
